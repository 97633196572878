import { type getUser } from './supabase/server'

export type ServerActionResult<Result> = Promise<
  | Result
  | {
      error: string
    }
>

export interface AuthResult {
  type: string
  message: string
}

export type Override<T, U> = Omit<T, keyof U> & U

export type User = NonNullable<Awaited<ReturnType<typeof getUser>>>

// This order determines the order in which the models are displayed
// but the actual values are fetched from supabase
export const MODELS = ['GPT 4o Mini', 'GPT 4o', 'Haiku', 'Sonnet'] as const

export type ModelName = (typeof MODELS)[number]
